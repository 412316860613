<template>
  <div class="home">
    <h1>Welcome</h1>
    <p>
      My Name is Milanowicz and I'm a developer by Github
    </p>
    <a href="https://github.com/milanowicz?tab=repositories" target="_blank">
      <img alt="GitHub logo" src="../assets/images/GitHub-Mark-Light-120px-plus.png">
    </a>
    <p>and Packagist</p>
    <a href="https://packagist.org/packages/milanowicz/php-testing" target="_blank">
      PHP Testing
    </a><br>
    <a href="https://packagist.org/packages/milanowicz/php-thread" target="_blank">
      PHP Thread(s)
    </a>
    <p>and NPM</p>
    <a href="https://www.npmjs.com/package/browser-detection.js" target="_blank">
      BrowserDetection.js
    </a><br>
    <a href="https://www.npmjs.com/package/csssasscollection" target="_blank">
      CSS Sass Collection
    </a>
    <p>and some other Projects</p>
    <a href="https://covid-19.milanowicz.dev/" target="_blank">
      <fa :icon="icon" class="covid" />
      Coronavirus (SARS-CoV-2)
    </a>
  </div>
</template>

<script>
import { faLungsVirus } from '@fortawesome/free-solid-svg-icons'
export default {
  name: 'ViewHome',
  data() {
    return {
      icon: faLungsVirus
    }
  }
}
</script>

<style>
.covid {
  color: #f44336!important;
}
</style>
